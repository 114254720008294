var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutBuyer',{attrs:{"sidebarActiveItem":{ value: 'admin_approval' }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("PENDING RFQS")]},proxy:true}])},[_c('div',{staticClass:"p-2 p-lg-5 h-100 overflow-auto bg-gray-light-4"},[(!_vm.orders)?_c('AppLoading',{attrs:{"fillSpace":""}}):_c('div',[_c('div',{staticClass:"d-none d-lg-block"},[_c('AppTable',{staticClass:"d-none h-100",attrs:{"headers":_vm.header,"items":_vm.orders.data,"total":_vm.orders.total,"currentPage":_vm.orders.current_page,"sort":_vm.sorts},on:{"sort":_vm.sortBy,"pageChange":_vm.pageChange},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var shownItem = ref.shownItem;
return [_c('td',[_vm._v(_vm._s(/** item.rqf_num */ shownItem.po_number || 'N/A'))]),_c('td',{staticClass:"text-truncate",staticStyle:{"max-width":"150px"}},[_vm._v(" "+_vm._s(shownItem.project_name)+" ")]),_c('td',{staticClass:"d-flex align-items-center"},[_c('img',{staticClass:"rounded-circle mr-3",attrs:{"src":require("@/assets/pocketyard_logo_black.svg"),"width":"30","height":"30"}}),_c('div',{staticStyle:{"width":"200px"}},[_c('p',{staticClass:"m-0 text-truncate"},[_vm._v(_vm._s(shownItem.full_name))]),_c('p',{staticClass:"m-0 text-gray text-truncate"},[_vm._v(" "+_vm._s(shownItem.user.company.name)+" ")])])]),_c('td',[_c('span',[_vm._v(_vm._s(_vm._f("readableDate")(shownItem.created_at,false)))]),_c('span',{staticClass:"ml-1 text-gray"},[_vm._v(" "+_vm._s(_vm._f("readableTime")(shownItem.created_at))+" ")])]),_c('td',[_vm._v(" Materials and Supplies ")]),_c('td',{staticClass:"text-info"},[_c('router-link',{staticClass:"\n                  order-table-action\n                  text-decoration-none\n                  d-flex\n                  align-items-center\n                ",attrs:{"to":{
                  name: 'OrderAdminApprovalView',
                  params: { id: shownItem.id },
                }}},[_c('AppIcon',{attrs:{"name":"document"}})],1)],1)]}}])})],1),_c('div',{staticClass:"d-lg-none"},[_vm._l((_vm.orders.data),function(shownItem,index){return _c('AppCard',{key:("order-item-" + index),staticClass:"p-3 mb-3",on:{"click":function($event){return _vm.$router.push({
              name: 'OrderAdminApprovalView',
              params: { id: shownItem.id },
            })}}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('span',{staticClass:"w-50 text-truncate"},[_vm._v(" "+_vm._s(shownItem.project_name)+" ")]),_c('div',[_c('span',{staticClass:"\n                  p-2\n                  d-inline-block\n                  text-center text-caption-2\n                  bg-special-light-4\n                "},[_vm._v(" "+_vm._s(_vm.STATUS.REQUEST_PENDING.buyer_text)+" ")])])]),_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_c('AppMediaViewer',{staticClass:"rounded-circle",attrs:{"width":"45px","aspectRatio":"1/1","src":shownItem.user.information
                    ? shownItem.user.information.profile_img
                    : ''}})],1),_c('div',{staticClass:"ml-2 d-flex flex-column"},[_c('span',{},[_vm._v(" "+_vm._s(_vm._f("userFullName")(shownItem.user.information))+" ")]),_c('span',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm._f("userCompany")(shownItem.user.information))+" ")])])]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-6 font-weight-3"},[_vm._v("RFQ")]),_c('div',{staticClass:"col-6"},[_vm._v(_vm._s(_vm._f("rfqFormat")(shownItem.id)))])]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-6 font-weight-3"},[_vm._v("PO Number")]),_c('div',{staticClass:"col-6"},[_vm._v(_vm._s(shownItem.po_number || 'N/A'))])]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-6 font-weight-3"},[_vm._v("Bidding Date End")]),_c('div',{staticClass:"col-6"},[_vm._v(" "+_vm._s(_vm._f("readableDate")(shownItem.bidding_end_date,false, true))+" ")])])])}),(_vm.orders.data)?_c('AppPagination',{attrs:{"hideDetails":"","centerPagination":"","itemsPerPage":"10","currentPage":_vm.orders.current_page,"total":_vm.orders.total},on:{"pageChange":_vm.pageChange}}):_vm._e()],2)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }